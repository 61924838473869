import React, { useState } from 'react';
import {HiddenInput, Slider, SwitchWrapper} from "./style";
const Switch = ({ checked, onChange }) => {
    const [isChecked, setIsChecked] = useState(checked);
    const handleChange = () => {
        const newChecked = !isChecked;
        setIsChecked(newChecked);
        if (onChange) {
            onChange(newChecked);
        }
    }
    return (
        <SwitchWrapper>
            <HiddenInput type="checkbox" checked={isChecked} onChange={handleChange} />
            <Slider checked={isChecked} />
        </SwitchWrapper>
    );
};

export default Switch;
