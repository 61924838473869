export const sendMessageToMobile = (messageText) => {
  if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.shareHandler
  ) {
    window.webkit.messageHandlers.shareHandler.postMessage({
      message: messageText
    });
  } else if (window.android) {
    const object = {
      message: messageText
    };
    const jsonString = JSON.stringify(object);
    window.android.onShare(jsonString);
  }
};