const ICON_NAMES = {
  micro: 'micro',
  play: 'play',
  playoff: 'playoff',
  'play-message': 'play-message',
  send: 'send',
  info: 'info',
  language: 'language',
  cross: 'cross',
  'arrow-right': 'arrow-right',
  request: 'request',
  bulletList: 'bullet-list',
  chat: 'chat',
  plus: 'plus'
};

export default ICON_NAMES;
