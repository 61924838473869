import React from 'react';

import { ReactComponent as LinkIcon } from '../../../images/icons/Link.svg';
import logo from '../../../images/icons/logo.svg';
import { RelatedLink, RelatedLinksContainer, RelatedLinksMessage, RelatedLinksText } from './style';
export const RelatedLinks = ({ links }) => {
  return (
    <RelatedLinksMessage>
      <RelatedLinksText>
        <img src={logo} alt="ezchatai logo" />
        Learn more
      </RelatedLinksText>
      <RelatedLinksContainer>
        {links.map(({ displayed_link, link }, index) => (
          <RelatedLink key={index}>
            <a rel="noreferrer" target="_blank" href={link}>
              {displayed_link.slice(displayed_link.indexOf('://') + 3)}
            </a>
            <LinkIcon />
          </RelatedLink>
        ))}
      </RelatedLinksContainer>
    </RelatedLinksMessage>
  );
};
