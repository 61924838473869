import React, { useEffect, useState } from 'react';

import { useCounter } from '../../hooks/useCounter';
import { getCalculatedDevices, getOrdersDevices } from '../../services/order';
import { Basket } from './Basket/Basket';
import { CreateAccount } from './CreateAccount/CreateAccount';
import Devices from './Devices/Devices';
import OrderDetails from './OrderDetails/OrderDetails';

export const OrderList = ({ setClosed }) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [productsCounters, updateProductCounter, deleteProductCounter] = useCounter();
  const [serviceCounter, updateServiceCounter, deleteServiceCounter] = useCounter();
  const [currentStep, setCurrentStep] = useState('devices-list');
  const [devicesInBasket, setDevicesInBasket] = useState([]);
  const [deliveryType, setDeliveryType] = useState(false);
  const [isOvernight, setIsOvernight] = useState(false);
  const [zip, setZip] = useState('');
  const updateDeviceCounter = (device, value) => {
    if (device.name) {
      updateProductCounter(device.id, value);
    } else {
      updateServiceCounter(device.id, value);
    }
  };
  useEffect(() => {
    if(!loading){
      calculateDevices()
    }
  },[deliveryType,isOvernight])
  const handleDeliveryTypeChange = () => {
    setDeliveryType((prevState) => !prevState);
  };
  const handleOvernightTypeChange = () => {
    setIsOvernight((prevState) => !prevState);
  };
  const getOvernightNumber = (isOvernight) => {
    if (!deliveryType) {
      setIsOvernight(false);
      return '';
    }
    if (isOvernight) {
      return '1';
    }
    return '';
  };

  const calculateDevices = async (referralCode = '') => {
    try {
      setLoading(true);
      const devices = {
        referralCode,
        overnight: getOvernightNumber(isOvernight),
        products: Object.fromEntries(
          Object.entries(productsCounters)
            .filter(([k, v]) => v > 0)
            .map(([k, v]) => [k, String(v)])
        ),
        services: Object.fromEntries(
          Object.entries(serviceCounter)
            .filter(([k, v]) => v > 0)
            .map(([k, v]) => [k, String(v)])
        ),
        deliveryCalculateRate: deliveryType,
        zip
      };
      const data = await getCalculatedDevices(devices);
      setDevicesInBasket(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const handleOrderDevices = async () => {
    try {
      const devices = await getOrdersDevices();
      setItems(devices);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleOrderDevices();
  }, []);
  useEffect(() => {
    if (Object.keys(items).length > 0) {
      [...items.products].forEach((device) => {
        updateProductCounter(device.id, 0);
      });
      [...items.services].forEach((service) => {
        updateServiceCounter(service.id, 0);
      });
      setLoading(false);
    }
  }, [items]);

  useEffect(() => {
    if (productsCounters && currentStep === 'basket-list') {
      calculateDevices();
    }
  }, [productsCounters]);
  useEffect(() => {
    if (serviceCounter && currentStep === 'basket-list') {
      calculateDevices();
    }
  }, [serviceCounter]);

  const getCurrentStepMenu = (currentStep) => {
    switch (currentStep) {
      case 'devices-list':
        return (
          <Devices
            loading={loading}
            setCurrentStep={setCurrentStep}
            items={items}
            productsCounters={productsCounters}
            serviceCounter={serviceCounter}
            updateDeviceCounter={updateDeviceCounter}
            setClosed={setClosed}
          />
        );
      case 'create-account':
        return (
          <CreateAccount
            setCurrentStep={setCurrentStep}
            loading={loading}
            setLoading={setLoading}
            setClosed={setClosed}
          />
        );
      case 'basket-list':
        return (
          <Basket
            calculateDevices={calculateDevices}
            deliveryType={deliveryType}
            isOvernight={isOvernight}
            devicesInBasket={devicesInBasket}
            handleOvernightTypeChange={handleOvernightTypeChange}
            handleDeliveryTypeChange={handleDeliveryTypeChange}
            setCurrentStep={setCurrentStep}
            deleteProduct={deleteProductCounter}
            deleteService={deleteServiceCounter}
            loading={loading}
            total={devicesInBasket?.product_calculate?.total}
            setClosed={setClosed}
          />
        );
      case 'order-details':
        return (
          <OrderDetails
            productsCounters={productsCounters}
            serviceCounter={serviceCounter}
            handleOvernightTypeChange={handleOvernightTypeChange}
            handleDeliveryTypeChange={handleDeliveryTypeChange}
            deliveryType={deliveryType}
            isOvernight={isOvernight}
            setCurrentStep={setCurrentStep}
            loading={loading}
            total={devicesInBasket?.product_calculate?.total}
            calculateDevices={calculateDevices}
            setLoading={setLoading}
            setClosed={setClosed}
            zip={zip}
            setZip={setZip}
          />
        );
    }
  };
  return <> {getCurrentStepMenu(currentStep)} </>;
};
