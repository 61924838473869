import './styles.scss';

import React, { useState } from 'react';
import styled from 'styled-components';

import { phoneNumberRegex } from '../../utils/regex/phoneNumberRegex';
import { sendUserEmailAndPhone } from '../../utils/utils';

export const ContactsForm = ({ setShowEmailAndPhoneForm }) => {
  const [userData, setUserData] = useState({ phone: '' });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (userData.phone) {
      if (!phoneNumberRegex.test(userData.phone)) {
        errors.phone = 'Please enter a valid phone number';
      }
    }

    return errors;
  };
  const onChange = (e) => {
    setUserData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      sendUserEmailAndPhone(userData).then((res) => {
        if (res) {
          setShowEmailAndPhoneForm(false);
        }
      });
    }
  };
  return (
    <div className="authorization-form">
      <StyledForm onSubmit={handleSubmit}>
        <Title>To assist you better, can you provide me your phone number or email address?</Title>
        <FormGroup>
          <Label htmlFor="phone">Phone</Label>
          <Input
            value={userData.phone}
            name="phone"
            onChange={onChange}
            placeholder="Enter you mobile"
          />
          {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
        </FormGroup>
        <SubmitButton type="submit">Submit</SubmitButton>
      </StyledForm>
    </div>
  );
};

const Input = styled.input(({ theme }) => ({
  height: '50px',
  width: '100%',
  padding: `${theme.px.x4}em ${theme.px.x4}em`,
  border: '1px solid #BDCCDB',
  // outline: '1px solid transparent',
  fontSize: theme.px.x4 + 'rem',
  lineHeight: theme.px.x5 + 'em',
  color: theme.color.textRegular,
  borderRadius: '6px',

  '&::placeholder': {
    fontSize: theme.px.x4 + 'rem',
    lineHeight: theme.px.x5 + 'em',

    color: theme.color.placeholder
  }
}));
const SubmitButton = styled.button(({ theme }) => ({
  height: '50px',
  backgroundColor: '#1da5ff',
  width: '100%',
  padding: `${theme.px.x4}em ${theme.px.x4}em`,
  border: '1px solid #BDCCDB',
  cursor: 'pointer',
  fontSize: theme.px.x4 + 'rem',
  lineHeight: theme.px.x5 + 'em',
  color: 'white',
  borderRadius: '6px',

  '&::placeholder': {
    fontSize: theme.px.x4 + 'rem',
    lineHeight: theme.px.x5 + 'em',

    color: theme.color.placeholder
  }
}));
const ErrorMessage = styled.span(({ theme }) => ({
  marginTop: '10px',
  color: 'red',
  textAlign: 'left'
}));
const FormGroup = styled.div`
  height: 90px;
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  font-size: 21px;
`;
export const StyledForm = styled.form`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  gap: 35px;
  border: 1px solid rgb(244, 244, 244);
  padding: 30px;
  border-radius: 6px;
`;
const Label = styled.label`
  text-align: left;
  margin-bottom: 5px;
`;
