import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { useLocation } from 'react-router-dom';

import { ReactComponent as PreviosStep } from '../../../images/icons/devices/backImg.svg';
import { ErrorMessage } from '../../../pages/Chat/styles';
import { createNewUser } from '../../../services/order';
import { useDefaultParams } from '../../../services/socket';
import { emailRegex } from '../../../utils/regex/emailRegex';
import { phoneNumberRegex } from '../../../utils/regex/phoneNumberRegex';
import { usdotRegex } from '../../../utils/regex/usdotRegex';
import { Loader } from '../../Loader/Loader';
import Switch from '../../Switch/Switch';
import { DevicesList } from '../Devices/style';
import { OrderDetailsWrapper } from '../OrderDetails/style';
import {
  Button,
  ButtonContainer,
  CancelButton,
  DeliveryContainer,
  DetailsInput,
  DevicesContent,
  Header,
  OrderContainer,
  SecondaryText,
  Title
} from '../style';

export const CreateAccount = ({ setCurrentStep, loading, setClosed, setLoading }) => {
  const [userData, setUserData] = useState({
    name: '',
    surname: '',
    phone: '',
    address: '',
    state: '',
    city: '',
    zip: '',
    email: ''
  });
  const { isUserAuthorized } = useDefaultParams();
  const location = useLocation();
  const [errors, setErrors] = useState({});
  const [usdot, setUsdot] = useState('');
  const [carrierName, setCarrierName] = useState('');
  const [isSoloUser, setIsSoloUser] = useState(false);
  const createUserDataObject = () => {
    const data = {
      first_name: userData.name,
      last_name: userData.surname,
      phone: userData.phone.replace(/\+/g, ''),
      email: userData.email,
      address: userData.address,
      state: userData.state,
      city: userData.city,
      zip: `${userData.zip}`,
      solo_driver: isSoloUser,
      usdot: !isSoloUser ? +usdot : 0,
      carrier_name: !isSoloUser ? carrierName : ''
    };
    return data;
  };
  useEffect(() => {
    if (isUserAuthorized || !location.search.slice(1)) {
      setCurrentStep('basket-list');
    }
  }, [isUserAuthorized]);

  const createUser = async () => {
    try {
      setLoading(true);
      const userData = createUserDataObject();
      const res = await createNewUser(userData);
      if (res === 200) {
        setCurrentStep('basket-list');
      }
    } catch (e) {
      setErrors((prevState) => ({ ...prevState, server: e?.response?.data?.error || e.message }));
    } finally {
      setLoading(false);
    }
  };
  const validateForm = () => {
    const newErrors = {};
    if (!userData.name) {
      newErrors.name = 'First name is required';
    }
    if (!userData.surname) {
      newErrors.surname = 'Last name is required';
    }
    if (!userData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!phoneNumberRegex.test(userData.phone)) {
      newErrors.phone = 'Please enter a valid phone number';
    }
    if (!userData.email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(userData.email)) {
      newErrors.email = 'Please enter a valid email';
    }
    if (!userData.address) {
      newErrors.address = 'Address is required';
    }
    if (!userData.state) {
      newErrors.state = 'State is required';
    }
    if (!userData.city) {
      newErrors.city = 'City is required';
    }
    if (!userData.zip) {
      newErrors.zip = 'Zip code is required';
    }
    if (!usdotRegex.test(userData.zip)) {
      newErrors.zip = 'The zip code consists only of numbers';
    }
    if (!usdot && !isSoloUser) {
      newErrors.usdot = 'Usdot code is required';
    }
    if (!usdotRegex.test(usdot) && !isSoloUser) {
      newErrors.usdot = 'The usdot code consists only of numbers';
    }
    if (!carrierName && !isSoloUser) {
      newErrors.carrierName = 'Carrier name is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      createUser();
    }
  };
  const handleStatusTypeChange = () => {
    setIsSoloUser((prevState) => !prevState);
    setUsdot('');
    setCarrierName('');
  };
  return (
    <form onSubmit={handleSubmit}>
      <OrderContainer>
        <Header>
          <PreviosStep
            onClick={() => setCurrentStep('devices-list')}
            style={{ cursor: 'pointer' }}
          />
          <Loader loading={loading} />
          <Title isAddMargin>Let`s create your account first</Title>
        </Header>
        <DevicesList>
          <DevicesContent>
            <div>
              <Title>User type</Title>
              <DeliveryContainer>
                <SecondaryText>Fleet</SecondaryText>
                <Switch onChange={handleStatusTypeChange} checked={!isSoloUser} />
                <SecondaryText>Solo</SecondaryText>
              </DeliveryContainer>
            </div>
            <div>
              <Title>Personal data</Title>
              <OrderDetailsWrapper>
                <div>
                  <DetailsInput
                    placeholder="First name"
                    value={userData.name}
                    onChange={(e) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        name: e.target.value
                      }));
                      setErrors({ ...errors, name: '' }); // Clear error when input changes
                    }}
                    error={errors.name}
                  />
                  {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                </div>
                <div>
                  <DetailsInput
                    placeholder="Last name"
                    value={userData.surname}
                    onChange={(e) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        surname: e.target.value
                      }));
                      setErrors({ ...errors, surname: '' }); // Clear error when input changes
                    }}
                    error={errors.surname}
                  />
                  {errors.surname && <ErrorMessage>{errors.surname}</ErrorMessage>}
                </div>
                <div>
                  <PhoneInput
                    inputComponent={DetailsInput}
                    international
                    value={userData.phone}
                    onChange={(phone) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        phone
                      }));
                      setErrors({ ...errors, phone: '' }); // Clear error when input changes
                    }}
                    placeholder="Phone number"
                  />
                  {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
                </div>
                <div>
                  <DetailsInput
                    placeholder="Email"
                    value={userData.email}
                    onChange={(e) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        email: e.target.value
                      }));
                      setErrors({ ...errors, email: '' }); // Clear error when input changes
                    }}
                    error={errors.email}
                  />
                  {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                </div>
                <div>
                  <DetailsInput
                    placeholder="Address"
                    value={userData.address}
                    onChange={(e) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        address: e.target.value
                      }));
                      setErrors({ ...errors, address: '' }); // Clear error when input changes
                    }}
                    error={errors.address}
                  />
                  {errors.address && <ErrorMessage>{errors.address}</ErrorMessage>}
                </div>
                <div>
                  <DetailsInput
                    placeholder="State"
                    value={userData.state}
                    onChange={(e) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        state: e.target.value
                      }));
                      setErrors({ ...errors, state: '' }); // Clear error when input changes
                    }}
                    error={errors.state}
                  />
                  {errors.state && <ErrorMessage>{errors.state}</ErrorMessage>}
                </div>
                <div>
                  <DetailsInput
                    placeholder="City"
                    value={userData.city}
                    onChange={(e) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        city: e.target.value
                      }));
                      setErrors({ ...errors, city: '' }); // Clear error when input changes
                    }}
                    error={errors.city}
                  />
                  {errors.city && <ErrorMessage>{errors.city}</ErrorMessage>}
                </div>
                <div>
                  <DetailsInput
                    placeholder="Zip code"
                    value={userData.zip}
                    onChange={(e) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        zip: e.target.value
                      }));
                      setErrors({ ...errors, zip: '' }); // Clear error when input changes
                    }}
                    error={errors.zip}
                  />
                  {errors.zip && <ErrorMessage>{errors.zip}</ErrorMessage>}
                </div>
                {!isSoloUser && (
                  <div>
                    <DetailsInput
                      placeholder="Carrier name"
                      value={carrierName}
                      onChange={(e) => {
                        setCarrierName(e.target.value);
                        setErrors({ ...errors, carrierName: '' }); // Clear error when input changes
                      }}
                      error={errors.carrierName}
                    />
                    {errors.carrierName && <ErrorMessage>{errors.carrierName}</ErrorMessage>}
                  </div>
                )}
                {!isSoloUser && (
                  <div>
                    <DetailsInput
                      placeholder="Usdot"
                      value={usdot}
                      onChange={(e) => {
                        setUsdot(e.target.value);
                        setErrors({ ...errors, usdot: '' }); // Clear error when input changes
                      }}
                      error={errors.usdot}
                    />
                    {errors.usdot && <ErrorMessage>{errors.usdot}</ErrorMessage>}
                  </div>
                )}
              </OrderDetailsWrapper>
            </div>
          </DevicesContent>
        </DevicesList>
        {errors.server && <ErrorMessage>{errors.server}</ErrorMessage>}
      </OrderContainer>
      <ButtonContainer>
        <Button type="submit">Next</Button>
        <CancelButton onClick={() => setClosed(true)}>Cancel</CancelButton>
      </ButtonContainer>
    </form>
  );
};
