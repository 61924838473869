import styled from 'styled-components';
const colorPrimary = '#1DA5FF';

export const NotificationsBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100wh',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '130%',
  backgroundColor: '#FFFFF',
  borderRadius: '12px',
  borderTop: '2px solid #1DA5FF',
  padding: '12px',
  color: '#1DA5FF',
  overflow: 'hidden'
});

export const NotificationsHeader = styled.div({
  display: 'flex',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '21px',
  paddingBottom: '12px',
  borderBottom: '1px solid #5ABB7B',
  marginBottom: '12px'
});

export const NotificationImage = styled.div({
  position: 'relative',
  top: '2px',
  '&::before': {
    content: ({ content }) => `"${content}"`,
    position: 'absolute',
    top: '-7px',
    right: '-7px',
    width: '14px',
    height: '14px',
    background: '#1DA5FF',
    color: '#ffffff',
    borderRadius: '50%',
    fontSize: '8px',
    fontWeight: '600',
    lineHeight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export const NotificationTitle = styled.p({
  marginLeft: '11px',
  flex: '1 1 auto'
});

export const NotificationButton = styled.button({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: colorPrimary,
  padding: '3px 6px',
  border: '1px solid #1DA5FF',
  borderRadius: '5px',
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '15px',
  color: '#FFFFFF',
  '&>:not(:last-child)': {
    marginRight: '9px'
  }
});

export const NotificationsWrapper = styled.div({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
});

export const Notification = styled.div({
  position: 'relative',
  display: 'flex',
  width: 'calc(100% - 20px)',
  padding: '8px 12px',
  background: '#DBEFFF',
  margin: '16px auto 0px 0px',
  borderRadius: '12px 12px 12px 0',
  gap: '0.5em',
});
export const NotificationMsg = styled.div({
  '& a': {
    color: colorPrimary
  }
});

export const NotificationTime = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  right: '12px',
  bottom: '2px',
  fontSize: '10px',
  fontWeight: '400',
  lineHeight: '16px',
  '&>:not(:last-child)': {
    marginRight: '8px',
    cursor: 'pointer'
  },
  '&:last-child': {
    color: 'rgba(29, 165, 255, 0.5)'
  }
});

export const LogoImg = styled.img({
  marginRight: '8px'
});
