import styled from 'styled-components';

import { MessageLiveAgent } from '../Message/styles';

export const RelatedLinksMessage = styled(MessageLiveAgent)(({ theme }) => ({
  flexDirection: 'column'
}));
export const RelatedLinksText = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.px.x2 + 'em'
}));
export const RelatedLinksContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.px.x2 + 'em',
  flexWrap: 'wrap'
}));
export const RelatedLink = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.px.x1 + 'em',
  borderRadius: '6px',
  padding: '2px 8px',
  backgroundColor: 'rgba(52, 151, 219, 0.1)',
  alignItems: 'center',
  '& a': {
    color: 'rgba(29, 165, 255, 1)',
    fontSize: theme.px.x4 + 'rem'
  }
}));
