import clipboardCopy from 'clipboard-copy';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { ReactComponent as CopyIcon } from '../../../images/icons/Copy.svg';
import { ReactComponent as DislikeIcon } from '../../../images/icons/Dislike.svg';
import { ReactComponent as DislikeIconContained } from '../../../images/icons/DislikeContained.svg';
import { ReactComponent as LikeIcon } from '../../../images/icons/Like.svg';
import { ReactComponent as LikeIconContained } from '../../../images/icons/LikeContained.svg';
import { ReactComponent as ShareIcon } from '../../../images/icons/Share.svg';
import { ReactComponent as CheckAll } from '../../../images/icons/Сheck-all.svg';
import { postLikeMessage } from '../../../services/chat';
import { Alert } from '../../Alert';
import {
  ActionText,
  CopiedTitle,
  MessageActions,
  MessageInteractionContainer,
  MessageReaction
} from './style';
import {Item} from "../ShowMessageInModal/style";

export const MessageInteraction = ({ isLiked, message_id, messageText, setCurrentActionMode }) => {
  const [isLikedMessage, setIsLikedMessage] = useState(isLiked);
  const [isMessageCopied, setIsMessageCopied] = useState(false);
  const dislikedMessage = isLikedMessage === false;
  const likedMessage = isLikedMessage === true;
  useEffect(() => {
    const copiedTimeout = setTimeout(() => {
      setIsMessageCopied(false);
    }, 3000);

    return () => {
      clearTimeout(copiedTimeout);
    };
  }, [isMessageCopied]);
  const setLikedMessage = async (likeStatus) => {
    const like = likeStatus === isLikedMessage ? null : likeStatus;

    try {
      const response = await postLikeMessage({ message_id, like });

      if (response.status === 200) {
        setIsLikedMessage(like);
      }
    } catch (error) {
      console.error('Failed to post like message:', error);
    }
  };
  const copyMessageTextToClipboard = () => {
    clipboardCopy(messageText)
      .then(() => setIsMessageCopied(true))
      .catch((error) => console.error('Failed to copy to clipboard:', error));
  };
  return (
    <>
      <MessageInteractionContainer>
        <MessageActions>
          {isMobile && (
            <Item
              onClick={() => setCurrentActionMode({ action: 'sharing', el: message_id })}>
              <ShareIcon />
              <ActionText>Share</ActionText>
            </Item>
          )}
          <Item onClick={copyMessageTextToClipboard}>
            <CopyIcon />
            <ActionText>{isMessageCopied ? 'Copied' : 'Copy'}</ActionText>
          </Item>
        </MessageActions>
        <MessageReaction>
          <Item onClick={() => setLikedMessage(false)}>
            {dislikedMessage ? (
              <DislikeIconContained width={18} height={17} />
            ) : (
              <DislikeIcon width={18} height={17} />
            )}
          </Item>
          <Item onClick={() => setLikedMessage(true)}>
            {likedMessage ? (
              <LikeIconContained width={18} height={17} />
            ) : (
              <LikeIcon width={18} height={17} />
            )}
          </Item>
        </MessageReaction>
        <Alert visible={isMessageCopied} type="success">
          <CheckAll />
          <CopiedTitle>Message is copied</CopiedTitle>
        </Alert>
      </MessageInteractionContainer>
    </>
  );
};
