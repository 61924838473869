import React from 'react';

import { CheckboxContainer, CheckboxLabel, HiddenCheckbox, StyledCheckbox } from './style';

const Checkbox = ({ label, checked, onChange, disabled }) => {
  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    if (onChange) {
      onChange(checked);
    }
  };
  const handleCheckboxClick = (event) => {
    event.stopPropagation(); // Stop the click event from propagating to the parent container
  };
  return (
    <CheckboxContainer>
      <HiddenCheckbox disabled={disabled} checked={checked} onChange={handleCheckboxChange} />
      <StyledCheckbox checked={checked} onClick={handleCheckboxClick}>
        {checked ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white">
            <path d="M5 13l4 4L19 7" stroke='white' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        ) : null}
      </StyledCheckbox>
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
};
export default Checkbox;
