import styled from "styled-components";

export const VerticalItem = styled.div`
  display: flex;
  gap: 12px;
`;
export const OrderDetailsWrapper = styled.div`
  margin: 15px 0 5px 0;
  display: flex;
  justify-content: center;
  gap: 21px;
  flex-direction: column;
`;
export const SubmitOrderButton = styled.button`
  color: white;
  cursor: pointer;
  padding: 8px 14px;
  border-radius: 4px;
  background-color: #1DA5FF;
  border: none;
`;