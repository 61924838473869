import './styles.scss';

import ICON_NAMES from 'constants/iconNames';
import Icon from 'kit/Icon';
import IconButton from 'kit/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { getChats } from 'services/chat';

import { shortenText } from '../../../utils/functions/shortenText';
import ModalPortal from '../../ModalPortal';

const ChatHistoryModal2 = ({
  showChatHistoryModal,
  onClickChatHistoryHandler,
  handleOpenNewChat
}) => {
  const [chats, setChats] = useState([]);
  const chatHistoryRef = useRef(null);
  const handleSubmit = (chat) => {
    if (chat) {
      handleOpenNewChat(chat);
    } else {
      handleOpenNewChat({ chat_id: null });
    }
    onClickChatHistoryHandler();
  };

  const getAllChats = async () => {
    const chats = await getChats();
    if (chats?.length) {
      setChats(chats);
    }
  };

  useEffect(() => {
    if (showChatHistoryModal) {
      getAllChats();
    }
  }, [showChatHistoryModal]);

  return (
    <ModalPortal show={showChatHistoryModal} onClick={onClickChatHistoryHandler}>
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={showChatHistoryModal}
        timeout={{ enter: 700, exit: 700 }}
        classNames="modal"
        nodeRef={chatHistoryRef}>
        <div className="chatHistory" ref={chatHistoryRef}>
          <div className="chatHistory_header">
            <h2 className="chatHistory_header_title">Chat’s history</h2>
            <IconButton
              width={11}
              height={11}
              iconName={ICON_NAMES.cross}
              buttonStyle={{ color: '#262626' }}
              onClick={onClickChatHistoryHandler}
            />
          </div>
          <div className="chatHistory_content">
            {chats?.map((el, i) => {
              return (
                <div
                  className="chatHistory_content_item"
                  key={el.title + i}
                  onClick={() => handleSubmit(el)}>
                  <Icon width={15} height={14} name={ICON_NAMES.chat} />
                  <span>{shortenText(el.title)}</span>
                </div>
              );
            })}
          </div>
          <div className="chatHistory_footer">
            <button className="chatHistory_footer_addBtn" onClick={() => handleSubmit()}>
              <Icon width={15} height={14} name={ICON_NAMES.plus} />
              <span>Add new chat</span>
            </button>
          </div>
        </div>
      </CSSTransition>
    </ModalPortal>
  );
};

export default ChatHistoryModal2;
