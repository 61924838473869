import React from 'react';

import {ReactComponent as DeleteIcon} from '../../../images/icons/devices/Delete.svg';
import {DeviceImage, OrderDescriptions, OrderName, OrderSide} from '../OrderItem/style';
import {
    CartItemContainer,
    CartItemContent,
    CartItemHeader, CartPriceSummary, CartSummaryPrice, CartSummaryText,
} from './style';
import styled from "styled-components";
import eld from "../../../images/icons/devices/eld.svg";
import sixPin from "../../../images/icons/devices/6_pin.png";
import sixteenPin from "../../../images/icons/devices/16_pin.png";
import ezsmartcam from "../../../images/icons/devices/ezsmartcam.svg";
import smartsafety from "../../../images/icons/devices/smartsafety.svg";
import stikers from "../../../images/icons/devices/stikers.png";
import ninePin from "../../../images/icons/devices/IMG_5592.jpg";
const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
`;
const images = {
    'eld.svg': eld,
    '6_pin.png': sixPin,
    '16_pin.png': sixteenPin,
    'ezsmartcam.svg': ezsmartcam,
    'smartsafety.svg': smartsafety,
    'stikers.png': stikers,
    'IMG_5592.jpg': ninePin
};
export const ProductCartItem = ({ product, removeProductFromBasket, id }) => {
    return (
        <CartItemContainer>
            <CartItemHeader>
                <OrderSide>
                    <div>
                        <DeviceImage src={images[product.img_url]} alt={product.img_url} />
                    </div>
                    <div>
                        <OrderName>{product.name}</OrderName>
                        <OrderDescriptions style={{fontSize: '11px', color: '#586771'}}>
                            {product.short_description}
                        </OrderDescriptions>
                    </div>
                </OrderSide>
                <StyledDeleteIcon onClick={() => removeProductFromBasket(id)}/>
            </CartItemHeader>
            <CartItemContent>
                <CartPriceSummary>
                    <CartSummaryText>Summary</CartSummaryText>
                    <CartSummaryPrice>${product.total}</CartSummaryPrice>
                </CartPriceSummary>
            </CartItemContent>
        </CartItemContainer>
    );
};
