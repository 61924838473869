import React, { useEffect, useRef } from 'react';

import FaQModule from './FaQModule';
import { Container, MessagesWrapper } from './Messages/Message/styles';
import MessagesList from './Messages/MessagesList';


const ChatContent = ({
  messages,
  isAudioEnabled,
  handleAudioPlaying,
  handleOnClickActionButton,
  isFirstLoad,
  isOpenFaQ,
  handleSubmitFaQ,
  faqList,
  handleOnClickTag,
  setMessages,
}) => {
  const revert_messages = messages?.sort((a, b) => a.message_id - b.message_id);
  const messagesBox = useRef(null);
  useEffect(() => {
    if (messagesBox.current) {
      messagesBox.current.addEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, []);
  return (
    <Container>
      {faqList?.length > 0 ? (
        <FaQModule
          faqList={faqList}
          handleSubmitFaQ={handleSubmitFaQ}
          isFirstLoad={isFirstLoad}
          isOpen={isOpenFaQ}
        />
      ) : null}
      <MessagesWrapper isHide={isOpenFaQ} ref={messagesBox}>
        <MessagesList
          messages={revert_messages}
          isAudioEnabled={isAudioEnabled}
          handleAudioPlaying={handleAudioPlaying}
          handleOnClickActionButton={handleOnClickActionButton}
          handleOnClickTag={handleOnClickTag}
          setMessages={setMessages}
        />
      </MessagesWrapper>
    </Container>
  );
};

export default ChatContent;
