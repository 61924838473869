import styled from 'styled-components';

export const OrderItemContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: '25px',
  padding: '14px',
  boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.12)',
  justifyContent: 'space-between',
  borderRadius: '4px',
  alignItems: 'center',
  width: 'calc(33.33% - 8px)',
  minWidth: '321px',
  margin: '6px 0',
  '@media (max-width: 768px)': {
    width: '100%',
    minWidth: 'auto' // Remove the minimum width constraint
  }
}));
export const OrderSide = styled.div(({ theme }) => ({
  display: 'flex',
  gap: '6px',
  alignItems: 'center',
  justifyContent: 'flex-end'
}));
export const OrderName = styled.span(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px'
}));
export const OrderDescriptions = styled.p(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 300,
  lineHeight: '15px',
  color: '#586771'
}));
export const DeviceImage = styled.img`
  width: 64px;
  height: 64px;
  display: block;
  object-fit: contain;
`;
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #DBEFFF;
  border-radius: 4px;
  cursor: pointer;
`;