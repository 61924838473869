import React from 'react';
import styled, { keyframes } from 'styled-components';

const waveSm =  keyframes`
  0% {
    opacity: 0.35;
    height: 10px;
  }
  100% {
    opacity: 1;
    height: 20px;
  }
`;

const waveMd =  keyframes`
  0% {
    opacity: 0.35;
    height: 15px;
  }
  100% {
    opacity: 1;
    height: 30px;
  }
`;

const waveLg =  keyframes`
  0% {
    opacity: 0.35;
    height: 15px;
  }
  100% {
    opacity: 1;
    height: 50px;
  }
`;

const BarsContainer = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Bar = styled.div`
  animation-name: ${waveLg};
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  background: white; // Change the color for the bars
  margin: 0 1.5px;
  height: 10px;
  width: 2px; // Change the number for the bar width

  &:nth-child(-n + 7),
  &:nth-last-child(-n + 7) {
    animation-name: ${waveMd};
  }

  &:nth-child(-n + 3),
  &:nth-last-child(-n + 3) {
    animation-name: ${waveSm};
  }
`;
export const SoundWave = () => {
  const bars = [];
  for (let i = 0; i < 5; i++) {
    bars.push(
      <Bar key={i} style={{ animationDuration: `${Math.random() * (0.7 - 0.2) + 0.2}s` }} />
    );
  }
  return <BarsContainer>{bars}</BarsContainer>;
};
