import { requests } from './api';

export const postAuthorization = (data = {}) =>
  requests
    .post({
      url: '/api/authorizations',
      data,
      configs: {
        withCredentials: true
      }
    })
    .then((res) => res?.data?.response?.jwt_token);

export const getGeneratedLink = (data = {}) =>
  requests
    .get({
      url: '/api/authorization/generate-link',
      data,
      configs: {
        withCredentials: true
      }
    })
    .then((res) => res?.data);
export const postUserAuthorizationData = (data = {}) =>
  requests
    .post({
      url: '/api/temp_user_data',
      data
    })
    .then((res) => res?.data);

export const postUserPhoneNumber = (data = {}) =>
  requests
    .post({
      url: '/api/sms_authorization',
      data
    })
    .then((res) => res?.data);
export const confirmUserSMS = (data = {}) =>
  requests
    .post({
      url: '/api/sms_confirmation',
      data
    })
    .then((res) => res?.data);
export const validateUserSession = (data = '') =>
  requests
    .post({
      url: '/api/authorization/external',
      data: { session: data }
    })
    .then((res) => res?.data);

export const checkUserAuthorized = () =>
  requests
    .get({
      url: '/api/authorization/token'
    })
    .then((res) => res?.data);
