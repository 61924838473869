import React from 'react';
import styled from 'styled-components';

export const Chat = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  fontFamily: 'Roboto, sans-serif',
  overflow: 'hidden',
  backgroundColor: theme.color.chatBackground,
  boxShadow: '0px 0px 5px rgba(29,165,255,.25), 0px 0px 60px rgba(29,165,255,.13)'
}));

export const ErrorMessage = styled.p({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FF7070',
  color: 'white'
});

export const Mask = styled.div`
  position: ${(props) => (props.show ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.show ? 'rgba(0, 0, 0, 0.5)' : 'rgb(244, 250, 255)')};
  z-index: 9999;
  display: block;
  padding: ${(props) => (props.show ? '0.5em;' : 0)};
  backdrop-filter: ${(props) => (props.show ? 'blur(9.5px)' : 'none')};
  overflow-y: auto;
  &::-webkit-scrollbar{
  display: none
}
`;
