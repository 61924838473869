import 'App.sass';

import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ROUTES } from 'router';
import { socket, useDefaultParams } from 'services/socket';
import { CONNECT } from 'services/socket/events';
import { onWSConnect } from 'services/socket/handlers';
import { fetchProjectParams, getRequiresContacts, handleAuth } from 'utils/utils';

import ClientPage from './pages/Chat';
import NotificationsPage from './pages/Notifications';
import { API, configureInterceptorsForProject } from './services/api';
import { checkUserAuthorized, validateUserSession } from './services/auth';
import { getActiveProject } from './utils/functions/getActiveProject';

const App = () => {
  const [isInitialLoading, setInitialLoading] = useState(true);
  const { isUserAuthorized, setIsUserAuthorized } = useDefaultParams();
  const [siteCookie, setSiteCookie] = useState({});
  const [showEmailAndPhoneForm, setShowEmailAndPhoneForm] = useState(false);
  const [projectParamsSettings, setProjectParamsSettings] = useState({});
  const location = useLocation();
  const projectIdJWT = getActiveProject(location);
  configureInterceptorsForProject(API, projectIdJWT);
  const fetchRequiresContacts = async () => {
    try {
      const isRequiredContacts = await getRequiresContacts();
      setShowEmailAndPhoneForm(isRequiredContacts);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAuthentication = async () => {
    try {
      await handleAuth();
    } catch (error) {
      console.log(error);
    }
  };
  const handleProjectsSettings = async () => {
    try {
      const projectSettings = await fetchProjectParams();
      setProjectParamsSettings(projectSettings);
    } catch (error) {
      console.log(error);
    }
  };
  const validateUserAuthorization = async () => {
    try {
      const isUserAuthorized = await checkUserAuthorized();
      if (isUserAuthorized === 'success') {
        setIsUserAuthorized(true);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchData = async () => {
    try {
      await handleAuthentication();
      await Promise.all([
        validateUserAuthorization(),
        handleProjectsSettings(),
        fetchRequiresContacts()
      ]);
    } catch (error) {
      console.log(error);
    }
    finally {
      setInitialLoading(false)
    }
  };
  function getCookieFromSite(event = null) {
    const cookies = event.data;
    if (
      event.origin === 'https://com1.test.ezlogz.com' ||
      event.origin === 'https://ezlogz.com' ||
      event.origin === 'https://app-dev.ezlogz.com' ||
      event.origin === 'https://app.ezlogz.com'
    ) {
      setSiteCookie(cookies);
    }
  }
  const validateUserAuthorizationPHP = async (session) => {
    try {
      await validateUserSession(session);
      setIsUserAuthorized(true);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (siteCookie?.session && !isUserAuthorized) {
      validateUserAuthorizationPHP(siteCookie.session);
    }
  }, [siteCookie]);
  useEffect(() => {
    fetchData();

    window.addEventListener('message', getCookieFromSite);
    socket.on(CONNECT, onWSConnect);
    return () => {
      socket.off(CONNECT, onWSConnect);
      window.removeEventListener('message', getCookieFromSite);
    };
  }, []);

  return (
    <>
      {!isInitialLoading && (
        <Routes>
          <Route
            path={ROUTES.homePage}
            element={
              <ClientPage
                projectParamsSettings={projectParamsSettings}
                setShowEmailAndPhoneForm={setShowEmailAndPhoneForm}
                showEmailAndPhoneForm={showEmailAndPhoneForm}
                id={projectIdJWT}
              />
            }
          />
          <Route path={ROUTES.notificationPage} element={<NotificationsPage />} />
        </Routes>
      )}
    </>
  );
};

export default App;
