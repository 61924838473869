import styled from 'styled-components';

export const MessagesReadingContainer = styled.div(({ theme }) => ({
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  gap: '14px',
  backgroundColor: theme.color.recordPopup,
  padding: `${theme.px.x3 + 'rem'} ${theme.px.x8 + 'rem'}`,
  borderRadius: theme.px.x3 + 'rem',
  position: 'fixed',
  left: 0,
  right: 0,
  top: '105px',
  zIndex: theme.zIndex.popup,
  width: 'fit-content',
  margin: '0 auto',
  boxShadow: '0px 0px 2px 0px rgba(7, 26, 40, 0.25), 0px 0px 2px 0px rgba(7, 26, 40, 0.25)',
  '& span': {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 500,
    color: theme.color.white
  }
}));
