import React, { useState } from 'react';

import ICON_NAMES from '../../../constants/iconNames';
import logo from '../../../images/icons/logo.svg';
import { ReactComponent as MoreIcon } from '../../../images/icons/More.svg';
import Icon from '../../../kit/Icon';
import { convertDateToLocal } from '../../../utils/functions/converDateToLocal';
import { DateMessage, MessageContent, MessageLiveAgent } from '../Message/styles';
import { MessageChip } from './style';

export const LiveAgentMessage = ({
  message,
  isAudioEnabled,
  handleAudioPlaying,
  currentDate,
  isLastMessage,
  setCurrentActionMode,
  messageId
}) => {
  return (
    <MessageLiveAgent>
      <img src={logo} alt="ezchatai logo" />
      <MessageContent>
        {message}
        <MessageChip>
          {!isLastMessage && (
            <div
              onClick={() => setCurrentActionMode({ action: 'more', el: messageId })}
              style={{
                height: '16px',
                width: '16px',
                backgroundColor: 'rgba(29, 165, 255, 1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '2px',
                cursor: 'pointer'
              }}>
              <MoreIcon />
            </div>
          )}
          <DateMessage>{convertDateToLocal(currentDate)}</DateMessage>
          {isAudioEnabled && (
            <Icon
              onClick={() => handleAudioPlaying(message)}
              style={{ cursor: 'pointer' }}
              width={18}
              height={18}
              name={ICON_NAMES['play-message']}
            />
          )}
        </MessageChip>
      </MessageContent>
    </MessageLiveAgent>
  );
};
