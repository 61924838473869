import ClientPage from 'pages/Chat';
import NotificationsPage from 'pages/Notifications';
import { createBrowserRouter } from 'react-router-dom';

export const ROUTES = {
  homePage: '/*',
  mainPage: '/client',
  authorizedPage: '/authorized',
  notificationPage: '/notifications'
};

export const router = createBrowserRouter([
  {
    path: ROUTES.homePage,
    element: <ClientPage />
  },
  {
    path: ROUTES.mainPage,
    element: <ClientPage />
  },
  {
    path: ROUTES.authorizedPage,
    element: <ClientPage />
  },
  {
    path: ROUTES.notificationPage,
    element: <NotificationsPage />
  }
]);
