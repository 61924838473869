import {UPDATE_LOCATIONS} from "./events";

export const getCurrentUserPosition = () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });
}
export const retrieveUserLocation = async (id) => {
    try {
        const position = await getCurrentUserPosition();
        const { latitude, longitude } = position.coords;
        // const jwtToken = Cookies.get('jwt_token');
        const jwtToken = localStorage.getItem('jwt_token_user');

        const data =  {
            jwt_token: jwtToken,
            latitude: latitude,
            longitude: longitude,
            project_id_jwt:id
        };
        return data
    } catch (error) {
        console.error('Error retrieving user location:', error);
    }
};
export const fetchLocationData = async (ws,id) => {
    const locationData = await retrieveUserLocation(id);
    if(locationData?.jwt_token){
        ws.emit(UPDATE_LOCATIONS, locationData);
    }
};