import styled from 'styled-components';

export const Item = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    cursor: 'pointer',
    height: 36,
    padding: '0 10px',
    boxShadow: '4px 2px 9px 0px #10283826',
    borderRadius: '6px',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: '1px solid rgba(142, 210, 255, 1)'
}));
export const Container = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.px.x2 + 'em',
    margin: `${theme.px.x1}em 0`,
    width: 'calc(100% - 20px)',
    backgroundColor: 'transparent',
    overflowY:'scroll',
    '&::-webkit-scrollbar':{
        display:'none'
    }
}));
export const Text = styled.span(({ theme }) => ({
    color: theme.color.colorPrimary,
    fontSize: theme.px.x4 + 'rem',
    whiteSpace: 'nowrap'
}));

export const Title = styled.div(({ theme }) => ({
    marginLeft: `${theme.px.x1}em`,
}));

export const VerticalContainer = styled.div(({ theme }) => ({
    margin: `${theme.px.x1}em 0`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.px.x1 + 'em',
    width:'fit-content',
}));