import ICON_NAMES from 'constants/iconNames';
import Icon from 'kit/Icon';
import styled from 'styled-components';

const FaQModule = ({isFirstLoad,isOpen,handleSubmitFaQ,faqList}) => {
    return (
        <>
            {isFirstLoad ? (
                <FaQModuleStyled isOpen={isOpen}>
                    {faqList.map((el, i) => {
                            return (
                                <FaQItem key={el.title + i} onClick={() => handleSubmitFaQ(el.title)}>
                                    {el.title}
                                    <Icon name={ICON_NAMES['arrow-right']}/>
                                </FaQItem>
                            );
                        })}
                    </FaQModuleStyled>
                )
                : (
                    <RequestModule isOpen={isOpen}>
                        <RequestChip/>
                        <RequestModuleTitle>Frequently asked questions</RequestModuleTitle>
                        <RequestContent>
                            {faqList.map((el, i) => {
                                return (
                                    <FaQItem key={el.title + i} onClick={() => handleSubmitFaQ(el.title)}>
                                        {el.title}
                                        <Icon name={ICON_NAMES['arrow-right']}/>
                                    </FaQItem>
                                );
                            })}
                        </RequestContent>
                    </RequestModule>
                )}
        </>
    );
};

export default FaQModule;

const FaQModuleStyled = styled.div(({theme, isOpen}) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: theme.color.module,
    overflowY: 'scroll',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    transition: 'transform linear 200ms',
    transform: isOpen ? 'translate(4px,0)' : 'translate(-100%,0)'
}));

const FaQItem = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    boxShadow: '1px 0px 6px 0px #00000040',
    padding: '11px 13px',
    borderRadius: '8px',
    fontWeight: 600,
    fontSize: '0.8rem',
    cursor: 'pointer'
}));

const RequestModule = styled.div(({theme, isOpen}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.module,
    height: '80vh',
    background: '#F4FAFF',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    transform: isOpen ? 'translate(0,11%)' : 'translate(0,120%)',
    transition: 'transform linear 250ms'
}));

const RequestModuleTitle = styled.h2({
    paddingTop: '41px',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    position: 'relative'
});

const RequestChip = styled.div({
    position: 'absolute',
    top: '16px',
    width: '2.5rem',
    height: '4px',
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
    left: ' 50%',
    transform: 'translateX(-50%)',
    boxShadow: '0px 1px 3px 0px #00000038 inset'
});

const RequestContent = styled.div({
    marginTop: '25px',
    padding: '4px 25px ',
    display: 'grid',
    gap: '16px',
    maxHeight: '65vh',
    overflowY: 'auto'
});
