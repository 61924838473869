import styled from 'styled-components';

export const Tab = styled.button(({ theme }) => ({
  padding: '8px',
  backgroundColor: '#DBEFFF',
  lineHeight: '18px',
  fontSize: theme.px.x3 + 'rem',
  border: 0,
  borderRadius: '4px',
  cursor: 'pointer',
  color: '#74818A',
  '&.active': {
    color: 'black' // font color for active tab
  }
}));
export const TabsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  margin: `${theme.px.x4}em 0`,
  flexWrap: 'wrap',
  padding: '0 1rem'
}));
export const DevicesList = styled.div(({ theme }) => ({
  height: '400px',
  overflowY: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0 12px',
  padding: '0 1rem',
  alignContent: 'flex-start'
}));
