import React from 'react';

import sixPin from '../../../images/icons/devices/6_pin.png';
import sixteenPin from '../../../images/icons/devices/16_pin.png';
import eld from '../../../images/icons/devices/eld.svg';
import ezsmartcam from '../../../images/icons/devices/ezsmartcam.svg';
import ninePin from '../../../images/icons/devices/IMG_5592.jpg';
import { ReactComponent as LineImage } from '../../../images/icons/devices/line.svg';
import { ReactComponent as PlusImage } from '../../../images/icons/devices/plus.svg';
import smartsafety from '../../../images/icons/devices/smartsafety.svg';
import stikers from '../../../images/icons/devices/stikers.png';
import {
    DeviceImage,
    IconContainer,
    InnerContainer,
    OrderDescriptions,
    OrderItemContainer,
    OrderName,
    OrderSide
} from './style';

const images = {
  'eld.svg': eld,
  '6_pin.png': sixPin,
  '16_pin.png': sixteenPin,
  'ezsmartcam.svg': ezsmartcam,
  'smartsafety.svg': smartsafety,
  'stikers.png': stikers,
  'IMG_5592.jpg': ninePin
};
export const OrderItem = ({
  device,
  name,
  deviceCounter,
  updateDeviceCounter,
  icon,
  price
}) => {
  const plusCounter = () => {
    updateDeviceCounter(device, deviceCounter + 1);
  };
  const minusCounter = () => {
    if (deviceCounter === 0) {
      return;
    }
    updateDeviceCounter(device, deviceCounter - 1);
  };
  return (
    <OrderItemContainer>
      <OrderSide>
        <div>
          <DeviceImage src={images[icon]} alt={icon} />
        </div>
        <div>
          <OrderName>{name}</OrderName>
        </div>
      </OrderSide>
      <InnerContainer>
        <div style={{ color: '#3497DB', textAlign: 'right' }}>${price}</div>
        <div style={{ display: 'flex', gap: '6px' }}>
          <IconContainer onClick={minusCounter}>
            <LineImage />
          </IconContainer>
          {deviceCounter}
          <IconContainer onClick={plusCounter}>
            <PlusImage />
          </IconContainer>
        </div>
      </InnerContainer>
    </OrderItemContainer>
  );
};
