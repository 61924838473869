import styled from 'styled-components';
export const AlertWrapper = styled.div`
  position: absolute;
  bottom: ${(props) => (props.visible ? '10%' : '-100%')};
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.type === 'success' ? '#3497DB' : '#f8d7da')};
  padding: 0.5rem 1rem;
  border-radius: 1.25rem;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  color: white;
  transition: bottom 0.5s;
  white-space: nowrap;
  z-index:1000;
`;