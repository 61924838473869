import Cookies from 'js-cookie';
import {getGeneratedLink, postAuthorization, postUserAuthorizationData} from 'services/auth';

import { getProjectParams, getRequires, postActionsButtons } from '../services/chat';

export const createCookie = (name, value) => {
  Cookies.set(name, value, { expires: 7 }); // saves 7 days
};

export const handleAuth = async () => {
  // let token = Cookies.get('jwt_token');
  let token = localStorage.getItem('jwt_token_user');
  if (!token) {
    const data = await postAuthorization();
    // createCookie('jwt_token', token);
    localStorage.setItem('jwt_token_user', data);
  }

  const isIncludesAuth = window.location.pathname.includes('authorized');

  if (isIncludesAuth) {
    const link = await getGeneratedLink(token);

    if (!link) {
      console.log(link);
      return;
    }
    window.location.replace(link);
  }
};

export const sendUserEmailAndPhone = async (userData) => {
  try {
    const data = await postUserAuthorizationData(userData);

    return !!data;
  } catch (e) {
    console.log(e);
  }
};

export const getRequiresContacts = async () => {
  try {
    const data = await getRequires();

    return !!data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchProjectParams = async () => {
  try {
    const data = await getProjectParams();

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const sendActionButton = async (actionButton) => {
  try {
    const data = await postActionsButtons(actionButton);

    return data;
  } catch (e) {
    console.log(e);
  }
};
