import styled from "styled-components";

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #74818a;
  cursor: pointer;
  font-weight: 500;
  vertical-align: middle
`;
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;
export const StyledCheckbox = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: all 0.2s ease;
  -webkit-appearance: none;
  ${HiddenCheckbox}:checked + & {
    background-color: #1e90ff;
    border-color: #1e90ff;
  }
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px rgba(30, 144, 255, 0.5);
  }
  svg {
    position: absolute;
    z-index: 100;
    opacity: ${(props) => (props.checked ? '1' : '0')};
  }
`;
export const CheckboxLabel = styled.span`
  font-size: 14px;
`;