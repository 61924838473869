import Backdrop from 'components/Backdrop';
import { TextRegular } from 'kit/text';
import { ReactMic } from 'react-mic';
import styled from 'styled-components';

const RecordModule = ({ hideModule, record, onStop, onData }) => {
  return (
    <>
      <Backdrop color={'transparent'} isOpen={record} onClose={hideModule} />
      <RecordModuleStyled isRecord={record}>
        <ReactMic
          visualSetting="sinewave"
          record={record}
          className="sound-wave"
          onStop={onStop}
          onData={onData}
          strokeColor="#F4FAFF"
          backgroundColor="#FF7070"
          noiseSuppression
        />
        <RecordTitle>
          JUST SAY <TextRegular>HOW CAN I HELP YOU</TextRegular>
        </RecordTitle>
      </RecordModuleStyled>
    </>
  );
};

export default RecordModule;

const RecordModuleStyled = styled.div(({ theme, isRecord }) => ({
  backgroundColor: theme.color.recordBackground,
  padding: theme.px.x7 + 'em',
  borderTopLeftRadius: '24px',
  borderTopRightRadius: '24px',
  position: 'absolute',
  bottom: '95%',
  left: 0,
  right: 0,
  zIndex: theme.zIndex.popup,
  transition: 'transform linear 200ms',
  transform: isRecord ? 'translate(0,0) scaleX(1)' : 'translate(-100%, 100%) scaleX(0)',
  '-webkit-user-select': 'none',
  '-moz-user-select': 'none',
  '-ms-user-select': 'none',
  'user-select': 'none'
}));

const RecordTitle = styled.div(({ theme }) => ({
  display: 'grid',
  textAlign: 'center',
  fontFamily: theme.font.bold,
  fontSize: theme.px.x4 + 'rem',
  lineHeight: theme.px.x5 + 'em',
  fontWeight: 900,
  color: '#fff',
  marginBottom: '160px'
}));
