import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';

export const Loader = ({ loading }) => {
  return (
    <SyncLoader
      color="#1DA5FF"
      loading={loading}
      cssOverride={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
      size={8}
      speedMultiplier={0.7}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};
