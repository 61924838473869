import React from 'react';

import { Dots } from '../../ChatFooter';
import { TotalOrderContainer, TotalOrderPrice, TotalOrderTitle } from './style';

export const SummaryOrderDetails = ({ loading, totalPrice }) => {
  const showTotalAmount = () => {
    if (totalPrice) {
      return `$${totalPrice.toFixed(2)}`;
    }
  };
  return (
    <TotalOrderContainer>
      <TotalOrderTitle>Total amount</TotalOrderTitle>
      <TotalOrderPrice>{loading ? <Dots count={3} /> : <>{showTotalAmount()} </>} </TotalOrderPrice>
    </TotalOrderContainer>
  );
};
